import React, { useState } from "react";
import { Alert } from "../../../../alert/alert";
import { TextField } from "@mui/material";
import { Button, Modal } from "rsuite";
import { useAppDispatch } from "../../../../store/hooks";
import { API } from "../../../../baseUrl/baseUrl";
import { getProdListAsync } from "../../../../generalUsesReducers/stockSlice";

const ChangePrice = ({ rowData, search, permissions }: any) => {
  const dispatch = useAppDispatch();
  const [newPrice, setNewPrice] = useState({
    mehsulID: 0,
    cheshidID: 0,
    olchuID: 0,
    warehouseID: 0,
    qiymet: Number(),
  });
  const [open, setOpen] = useState(false);
  const { mehsulID, cheshidID, olchuID, warehouseID, qiymet } = rowData;

  const handleOpenEdit = async () => {
    if (permissions.includes("STOK İDARƏSİ")) {
      setOpen(true);
      setNewPrice({
        mehsulID: Number(mehsulID),
        cheshidID: Number(cheshidID),
        olchuID: Number(olchuID),
        warehouseID: Number(warehouseID),
        qiymet: Number(qiymet),
      });
    }
  };
  const handleClose = () => setOpen(false);

  const sendNewData = async () => {
    try {
      const response = await API.put(`/stock`, newPrice);
      dispatch(getProdListAsync(search));
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      Alert({
        type: "error",
        title: error?.response?.data,
        placement: "topCenter",
        duration: 5000,
      });
      console.log(error);
    }
  };
  return (
    <div>
      <>
        <Button
          appearance="link"
          onClick={() => {
            handleOpenEdit();
          }}
        >
          {qiymet}
        </Button>
        <div>
          <Modal open={open} onClose={handleClose}>
            <Modal.Header>
              <Modal.Title>
                <div>
                  <h4>Düzəliş</h4>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className="modals-h4">{`${rowData.warehouseKey.warehouse}`}</h4>
              <h4 className="modals-h4">{`${rowData?.mehsul_?.mehsul}-${rowData?.cheshid_?.cheshid}-${rowData.kod_?.kod}`}</h4>
              <div className="textfield" style={{ textAlign: "center" }}>
                <form
                  id="modal-textfield"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleClose();
                    sendNewData();
                  }}
                >
                  <TextField
                    autoFocus
                    className="textfield"
                    style={{
                      margin: "0 1em",
                      width: "15em",
                      background: "white",
                    }}
                    label="Qiymət"
                    type="number"
                    size="small"
                    value={newPrice.qiymet}
                    onChange={(e: any) => {
                      setNewPrice({
                        ...newPrice,
                        qiymet: Number(e.target.value),
                      });
                    }}
                  />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button form="modal-textfield" type="submit" appearance="primary">
                Təsdiq et
              </Button>
              <Button onClick={handleClose} appearance="subtle">
                Geri qayıt
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    </div>
  );
};

export default ChangePrice;
