import React, { useState } from "react";
import { Modal, Button } from "rsuite";
import { TextField } from "@mui/material";
import { API } from "../../../baseUrl/baseUrl";
import { useAppDispatch } from "../../../store/hooks";
import { getProdListAsync } from "../../../generalUsesReducers/stockSlice";
import { Alert } from "../../../alert/alert";
import { searchParametersInterface } from "../../../interfaces/interfaces";
import { useAuth } from "../../../Sign/Auth";

const FakturaModal = ({
  rowData,
  search,
  permissions,
}: {
  rowData: any;
  search: searchParametersInterface;
  permissions: any;
}) => {
  const { decode } = useAuth();

  const dispatch = useAppDispatch();
  const { mehsul_, cheshid_, kod_, id, qiymet } = rowData;
  const [openFaktura, setOpenFaktura] = useState(false);
  const [faktura_, setFaktura] = useState({
    faktura: "",
    maya_deyeri: Number(),
    say: Number(),
    tarix: String() || null,
    stockID: Number(),
  });

  const handleOpenFaktura = () => {
    if (permissions.includes("STOK İDARƏSİ")) {
      setOpenFaktura(true);
      setFaktura({ ...faktura_, stockID: id });
    } else if (decode?.warehouseID === rowData.warehouseID) {
      setOpenFaktura(true);
      setFaktura({
        ...faktura_,
        maya_deyeri: Number(rowData.qiymet),
        faktura: "Geri qaytarma",
        stockID: id,
      });
    }
  };

  const handleClose = () => setOpenFaktura(false);

  const postNewFaktura = async () => {
    try {
      const response = await API.post(`/faktura/`, faktura_);
      dispatch(getProdListAsync(search));
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      Alert({
        type: "error",
        title: error.response?.data,
        placement: "bottomCenter",
        duration: 5000,
      });
      console.log(error);
    }
  };
  return (
    <>
      <Button
        title="Faktura daxil edin"
        appearance="link"
        onClick={handleOpenFaktura}
      >
        {mehsul_?.mehsul}
      </Button>

      <div>
        <Modal open={openFaktura} onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <div>
                <h3 style={{ margin: "0", padding: "0" }}>
                  Faktura daxil edin
                </h3>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br />
            <h4 className="modals-h4">
              {`${mehsul_?.mehsul}-${cheshid_?.cheshid}-${kod_?.kod}`}:
            </h4>
            <div className="textfield" style={{ textAlign: "center" }}>
              <form
                id="modal-textfield"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleClose();
                  postNewFaktura();
                }}
              >
                {permissions.includes("STOK İDARƏSİ") ? (
                  <>
                    <TextField
                      autoFocus
                      autoComplete="off"
                      style={{ margin: "0.5em 3em", width: "18em" }}
                      label="Faktura"
                      variant="outlined"
                      size="small"
                      value={faktura_.faktura}
                      onChange={(e) => {
                        setFaktura({ ...faktura_, faktura: e.target.value });
                      }}
                    />
                    <TextField
                      required
                      autoComplete="off"
                      style={{ margin: "0.5em 3em", width: "18em" }}
                      label="Maya dəyəri"
                      variant="outlined"
                      size="small"
                      onChange={(e) => {
                        setFaktura({
                          ...faktura_,
                          maya_deyeri: Number(e.target.value),
                        });
                      }}
                    />
                  </>
                ) : (
                  <>
                    <TextField
                      autoComplete="off"
                      required
                      style={{ margin: "0.5em 3em", width: "18em" }}
                      label="Faktura"
                      variant="outlined"
                      size="small"
                      defaultValue="Geri qaytarma"
                      inputProps={{ readOnly: true }}
                    />
                    <TextField
                      required
                      autoComplete="off"
                      style={{ margin: "0.5em 3em", width: "18em" }}
                      label="Maya dəyəri"
                      variant="outlined"
                      size="small"
                      defaultValue={qiymet}
                      inputProps={{ readOnly: true }}
                    />
                  </>
                )}

                <TextField
                  required
                  autoComplete="off"
                  style={{ margin: "0.5em 3em", width: "18em" }}
                  label="Say"
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    setFaktura({
                      ...faktura_,
                      say: parseFloat(e.target.value),
                    });
                  }}
                />
                {permissions.includes("STOK İDARƏSİ") ? (
                  <TextField
                    style={{ margin: "0.5em 3em", width: "18em" }}
                    label=""
                    type="date"
                    variant="outlined"
                    size="small"
                    value={faktura_.tarix}
                    onChange={(e) => {
                      setFaktura({ ...faktura_, tarix: e.target.value });
                    }}
                  />
                ) : (
                  <TextField
                    style={{ margin: "0.5em 3em", width: "18em" }}
                    label=""
                    type="date"
                    variant="outlined"
                    size="small"
                    defaultValue={faktura_.tarix}
                    inputProps={{ readOnly: true }}
                  />
                )}
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button form="modal-textfield" type="submit" appearance="primary">
              Təsdiq et
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Geri qayıt
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default FakturaModal;
