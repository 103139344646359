import React from "react";
import { Table, Loader } from "rsuite";
import DeleteModal from "../../../generalComponents/DeleteModal";
import { API } from "../../../baseUrl/baseUrl";
import { useAppSelector } from "../../../store/hooks";
import { Alert } from "../../../alert/alert";
import Paginations from "../../../generalComponents/Paginations";
import format from "date-fns/format";
const { Column, HeaderCell, Cell } = Table;

export default function TableTarixche({
  searchItems,
  setSearchItems,
  page,
  setPage,
  sales,
  getData,
  permissions,
}: {
  searchItems: any;
  setSearchItems: any;
  page: number;
  setPage: any;
  sales: any;
  getData: any;
  permissions: any;
}) {
  const { load } = useAppSelector((state) => state.loading);

  const handleDelete = async (id: number) => {
    try {
      const response = await API.delete(`/sales/${id}`);
      getData();
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      Alert({
        type: "error",
        title: error?.response.data,
        placement: "topCenter",
        duration: 5000,
      });
      console.log(error.response?.data);
    }
  };

  return (
    <div>
      <div className="table-place-div" style={{ height: 460 }}>
        {load === true ? (
          <Loader center size="lg" />
        ) : (
          <Table
            className="table-alish-olculer fk-st"
            height={450}
            data={sales[0]}
            onRowClick={(rowData: object) => {
              console.log(rowData);
            }}
          >
            <Column width={200} align="center" flexGrow={1}>
              <HeaderCell>Məhsul</HeaderCell>
              <Cell dataKey="stocks.mehsul_.mehsul" />
            </Column>
            <Column width={200} align="center" flexGrow={2}>
              <HeaderCell>Çeşid</HeaderCell>
              <Cell dataKey="stocks.cheshid_.cheshid" />
            </Column>
            <Column width={200} align="center">
              <HeaderCell>Kod</HeaderCell>
              <Cell dataKey="stocks.kod_.kod" />
            </Column>
            <Column width={100} align="center">
              <HeaderCell>Ölçü vahidi</HeaderCell>
              <Cell dataKey="stocks.olchu_.olchu" />
            </Column>
            <Column width={120} align="center">
              <HeaderCell>Kampaniya endirimi</HeaderCell>
              <Cell dataKey="kampaniya_endirimi" />
            </Column>
            <Column width={100} align="center">
              <HeaderCell>Miqdar</HeaderCell>
              <Cell dataKey="say" />
            </Column>
            <Column width={100} align="center">
              <HeaderCell>Qiymət</HeaderCell>
              <Cell dataKey="qiymet" />
            </Column>
            <Column width={100} align="center">
              <HeaderCell>Məbləğ</HeaderCell>
              <Cell>
                {(rowData) =>
                  Math.round(rowData?.qiymet * rowData?.say * 100) / 100
                }
              </Cell>
            </Column>
            <Column width={100} align="center">
              <HeaderCell>Anbar</HeaderCell>
              <Cell dataKey="stocks.warehouseKey.warehouse" />
            </Column>
            <Column width={190} align="center">
              <HeaderCell>Tarix</HeaderCell>
              <Cell>
                {(rowData) =>
                  rowData?.tarix &&
                  format(
                    new Date(rowData?.tarix).setHours(
                      new Date(rowData?.tarix).getHours() - 4
                    ),
                    "dd.MM.yyyy / HH:mm:ss"
                  )
                }
              </Cell>
            </Column>
            {permissions?.includes("STOK İDARƏSİ") ? (
              <Column width={90} align="center" fixed="right">
                <HeaderCell>#</HeaderCell>
                <Cell style={{ padding: "5px" }}>
                  {(rowData) => (
                    <DeleteModal
                      rowData={rowData}
                      handleDelete={handleDelete}
                    />
                  )}
                </Cell>
              </Column>
            ) : null}
          </Table>
        )}
      </div>
      <Paginations
        totalPage={sales[1]}
        page={page}
        setPage={setPage}
        searchData={searchItems}
        setSearchData={setSearchItems}
      />
    </div>
  );
}
