import React, { useEffect, useState } from "react";
import format from "date-fns/format";
import { useAppDispatch } from "../../store/hooks";
import { getTransferListAsync } from "../../generalUsesReducers/getTransferData";
import SearchTransfer from "./components/SearchTransfer";
import TransferTable from "./components/TransferTable";
import { searchTransferInteface } from "../../interfaces/interfaces";

const TransferStorage = () => {
  const dispatch = useAppDispatch();

  const today = format(new Date(), "yyyy-MM-dd");
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() + 1);

  const [page, setPage] = useState(1);

  const [searchItems, setSearchItems] = useState<searchTransferInteface>({
    mehsulID: 0,
    cheshidID: 0,
    kodID: 0,
    olchuID: 0,
    warehouseIDSource: 0,
    warehouseIDTarget: 0,
    barkod: "",
    date1: today,
    date2: format(yesterday, "yyyy-MM-dd"),
    take: 10,
    skip: 1,
  });

  const getTransferData = async () => {
    try {
      dispatch(getTransferListAsync(searchItems));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTransferData();
  }, [searchItems, page]);

  return (
    <div className="table-faktura">
      <SearchTransfer
        searchItems={searchItems}
        setSearchItems={setSearchItems}
        setPage={setPage}
      />
      <TransferTable
        searchItems={searchItems}
        setSearchItems={setSearchItems}
        page={page}
        setPage={setPage}
      />
    </div>
  );
};

export default TransferStorage;
