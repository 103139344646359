import React from "react";
import { Table, Loader } from "rsuite";
import DeleteModal from "../../../generalComponents/DeleteModal";
import EditModal from "./EditModal";
import { getFakturaListAsync } from "../../../generalUsesReducers/getFakturaSlice";
import { API } from "../../../baseUrl/baseUrl";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { searchItemsFakturaInterface } from "../../../interfaces/interfaces";
import { Alert } from "../../../alert/alert";
import Paginations from "../../../generalComponents/Paginations";
import format from "date-fns/format";

const { Column, HeaderCell, Cell } = Table;

export default function TableFaktura({
  searchItems,
  setSearchItems,
  page,
  setPage,
}: {
  searchItems: searchItemsFakturaInterface;
  setSearchItems: any;
  page: number;
  setPage: any;
}) {
  const dispatch = useAppDispatch();
  const { faktura } = useAppSelector((state) => state.fakturaList);
  const { load } = useAppSelector((state) => state.loading);

  const handleDelete = async (id: number) => {
    try {
      const response = await API.delete(`/faktura/${id}`);
      setTimeout(() => {
        dispatch(getFakturaListAsync(searchItems));
      }, 100);
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      Alert({
        type: "error",
        title: error?.response.data,
        placement: "topCenter",
        duration: 5000,
      });
      console.log(error.response?.data);
    }
  };

  return (
    <div>
      <div className="table-place-div">
        {load === true ? (
          <Loader center size="lg" />
        ) : (
          <Table
            className="table-alish-olculer"
            height={500}
            data={faktura[0]}
            onRowClick={(rowData: object) => {
              console.log(rowData);
            }}
          >
            <Column width={180} align="center" flexGrow={1} fixed>
              <HeaderCell>Məhsul</HeaderCell>
              <Cell dataKey="stocks.mehsul_.mehsul" />
            </Column>
            <Column width={180} align="center" flexGrow={2}>
              <HeaderCell>Çeşid</HeaderCell>
              <Cell dataKey="stocks.cheshid_.cheshid" />
            </Column>
            <Column width={140} align="center">
              <HeaderCell>Kod</HeaderCell>
              <Cell dataKey="stocks.kod_.kod" />
            </Column>
            <Column width={100} align="center">
              <HeaderCell>Miqdar</HeaderCell>
              <Cell dataKey="say" />
            </Column>
            <Column width={90} align="center">
              <HeaderCell>Ölçü vahidi</HeaderCell>
              <Cell dataKey="stocks.olchu_.olchu" />
            </Column>
            <Column width={130} align="center">
              <HeaderCell>Maya dəyəri</HeaderCell>
              <Cell dataKey="maya_deyeri" />
            </Column>
            <Column width={120} align="center">
              <HeaderCell>Anbar</HeaderCell>
              <Cell dataKey="stocks.warehouseKey.warehouse" />
            </Column>
            <Column width={260} align="center">
              <HeaderCell>Faktura</HeaderCell>
              <Cell dataKey="faktura" />
            </Column>
            <Column width={180} align="center">
              <HeaderCell>Tarix</HeaderCell>
              <Cell>
                {(rowData) =>
                  rowData?.tarix &&
                  format(
                    new Date(rowData?.tarix).setHours(
                      new Date(rowData?.tarix).getHours() - 4
                    ),
                    "dd.MM.yyyy / HH:mm:ss"
                  )
                }
              </Cell>
            </Column>

            <Column width={80} align="center" fixed="right">
              <HeaderCell>#</HeaderCell>
              <Cell style={{ padding: "6px" }}>
                {(rowData) => (
                  <EditModal rowData={rowData} searchItems={searchItems} />
                )}
              </Cell>
            </Column>
            <Column width={80} align="center" fixed="right">
              <HeaderCell>#</HeaderCell>
              <Cell style={{ padding: "5px" }}>
                {(rowData) => (
                  <DeleteModal rowData={rowData} handleDelete={handleDelete} />
                )}
              </Cell>
            </Column>
          </Table>
        )}
      </div>
      <Paginations
        totalPage={faktura[1]}
        page={page}
        setPage={setPage}
        searchData={searchItems}
        setSearchData={setSearchItems}
      />
    </div>
  );
}
