import React from "react";
import Paginations from "../../../generalComponents/Paginations";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { API } from "../../../baseUrl/baseUrl";
import { getTransferListAsync } from "../../../generalUsesReducers/getTransferData";
import { Alert } from "../../../alert/alert";
import { Loader, Table } from "rsuite";
import DeleteModal from "../../../generalComponents/DeleteModal";
import format from "date-fns/format";

const { Column, HeaderCell, Cell } = Table;

const TransferTable = (props: any) => {
  const { searchItems, setSearchItems, page, setPage } = props;
  const dispatch = useAppDispatch();
  const { transfer } = useAppSelector((state) => state.transferList);
  const { load } = useAppSelector((state) => state.loading);

  const handleDelete = async (id: number) => {
    try {
      const response = await API.delete(`/transfer/${id}`);
      setTimeout(() => {
        dispatch(getTransferListAsync(searchItems));
      }, 100);
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      Alert({
        type: "error",
        title: error?.response.data,
        placement: "topCenter",
        duration: 5000,
      });
      console.log(error.response?.data);
    }
  };

  return (
    <div>
      <div className="table-place-div">
        {load === true ? (
          <Loader center size="lg" />
        ) : (
          <Table
            className="table-alish-olculer fk-st"
            height={500}
            data={transfer[0]}
            onRowClick={(rowData: object) => {
              console.log(rowData);
            }}
          >
            <Column width={180} align="center" flexGrow={1}>
              <HeaderCell>Məhsul</HeaderCell>
              <Cell dataKey="products.mehsul" />
            </Column>
            <Column width={180} align="center" flexGrow={2}>
              <HeaderCell>Çeşid</HeaderCell>
              <Cell dataKey="cheshid_.cheshid" />
            </Column>
            <Column width={140} align="center">
              <HeaderCell>Kod</HeaderCell>
              <Cell dataKey="kod_.kod" />
            </Column>
            <Column width={100} align="center">
              <HeaderCell>Miqdar</HeaderCell>
              <Cell dataKey="say" />
            </Column>
            <Column width={100} align="center">
              <HeaderCell>Ölçü vahidi</HeaderCell>
              <Cell dataKey="olchu_.olchu" />
            </Column>
            <Column width={120} align="center">
              <HeaderCell>Anbardan</HeaderCell>
              <Cell dataKey="warehouseSource.warehouse" />
            </Column>
            <Column width={120} align="center">
              <HeaderCell>Anbara</HeaderCell>
              <Cell dataKey="warehouseTarget.warehouse" />
            </Column>
            <Column width={190} align="center">
              <HeaderCell>Barkod</HeaderCell>
              <Cell dataKey="barkod" />
            </Column>
            <Column width={140} align="center">
              <HeaderCell>Tarix</HeaderCell>
              <Cell>
                {(rowData) =>
                  rowData?.tarix &&
                  // format(new Date(rowData?.tarix), `dd.MM.yyyy / HH:mm`)
                  format(
                    new Date(rowData?.tarix).setHours(
                      new Date(rowData?.tarix).getHours() - 4
                    ),
                    "dd.MM.yyyy / HH:mm"
                  )
                }
              </Cell>
            </Column>

            <Column width={80} align="center" fixed="right">
              <HeaderCell>#</HeaderCell>
              <Cell style={{ padding: "5px" }}>
                {(rowData) => (
                  <DeleteModal rowData={rowData} handleDelete={handleDelete} />
                )}
              </Cell>
            </Column>
          </Table>
        )}
      </div>
      <Paginations
        totalPage={transfer[1]}
        page={page}
        setPage={setPage}
        searchData={searchItems}
        setSearchData={setSearchItems}
      />
    </div>
  );
};

export default TransferTable;
