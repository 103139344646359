import React from "react";
import { useAppSelector } from "../../../store/hooks";
import { SelectPicker } from "rsuite";
import { useAppDispatch } from "../../../store/hooks";
import { DateRangePicker } from "rsuite";
import format from "date-fns/format";
import Returned from "./Returned";
import { getCheshidTarixceListAsync } from "../../../generalUsesReducers/getCheshidTarixceSlice";
import { getKodTarixceListAsync } from "../../../generalUsesReducers/getKodTarixceSlice";

const SearchTarixche = ({
  searchItems,
  setSearchItems,
  setPage,
  permissions,
  searchFaktura,
  setSearchFaktura,
  cemiQaytarma,
}: {
  searchItems: any;
  setSearchItems: Function;
  setPage: Function;
  permissions: any;
  searchFaktura: any;
  setSearchFaktura: any;
  cemiQaytarma: number;
}) => {
  const dispatch = useAppDispatch();
  const { mehsullar } = useAppSelector((state) => state.mehsulList);
  const { cheshids } = useAppSelector((state) => state.cheshidTarixceList);
  const { kod_ } = useAppSelector((state) => state.kodTarixceList);
  const { warehouse_ } = useAppSelector((state) => state.warehouseList);
  const { faktura } = useAppSelector((state) => state.fakturaList);

  return (
    <>
      <div style={{ marginTop: "4em", position: "relative" }}>
        <section className="tableAlish-select-section">
          <div>
            <SelectPicker
              style={{ width: 180, marginLeft: "1em" }}
              data={mehsullar}
              size="lg"
              labelKey="mehsul"
              valueKey="id"
              placeholder="Məhsul"
              onSelect={(event: any) => {
                setSearchItems({
                  ...searchItems,
                  mehsulID: event,
                  skip: 1,
                });
                setPage(1);
                dispatch(getCheshidTarixceListAsync(event));
              }}
              onClean={() => {
                setSearchItems({
                  ...searchItems,
                  mehsulID: 0,
                  skip: 1,
                });
                setPage(1);
                dispatch(getCheshidTarixceListAsync(0));
              }}
            />
          </div>
          <SelectPicker
            virtualized
            style={{ width: 180, marginLeft: "1em" }}
            data={cheshids}
            size="lg"
            labelKey="cheshid"
            valueKey="id"
            placeholder="Çeşid"
            onSelect={(event: any) => {
              setSearchItems({
                ...searchItems,
                cheshidID: event,
                skip: 1,
              });
              setPage(1);
              dispatch(getKodTarixceListAsync(event));
            }}
            onClean={() => {
              setSearchItems({
                ...searchItems,
                cheshidID: 0,
                skip: 1,
              });
              setPage(1);
              dispatch(getKodTarixceListAsync(0));
            }}
          />
          <SelectPicker
            virtualized
            style={{ width: 180, marginLeft: "1em" }}
            data={kod_}
            size="lg"
            labelKey="kod"
            valueKey="id"
            placeholder="Kod"
            onSelect={(event: any) => {
              setSearchItems({
                ...searchItems,
                kodID: event,
                skip: 1,
              });
              setPage(1);
            }}
            onClean={() => {
              setSearchItems({
                ...searchItems,
                kodID: 0,
                skip: 1,
              });
              setPage(1);
            }}
          />
          {permissions.includes("STOK İDARƏSİ") ? (
            <SelectPicker
              style={{ width: 200, marginLeft: "1em" }}
              data={warehouse_}
              size="lg"
              labelKey="warehouse"
              valueKey="id"
              placeholder="Mağaza"
              onSelect={(event: any) => {
                setSearchItems({
                  ...searchItems,
                  warehouseID: Number(event),
                  skip: 1,
                });
                setSearchFaktura({
                  ...searchFaktura,
                  warehouseID: Number(event),
                });

                setPage(1);
              }}
              onClean={() => {
                setSearchItems({
                  ...searchItems,
                  warehouseID: 0,
                  skip: 1,
                });
                setSearchFaktura({
                  ...searchFaktura,
                  warehouseID: 0,
                });
                setPage(1);
              }}
            />
          ) : null}
          <DateRangePicker
            style={{ marginLeft: "1em" }}
            appearance="default"
            size="lg"
            placeholder={`${searchItems?.date1} ~ ${searchItems?.date2}`}
            placement="bottomEnd"
            cleanable={false}
            menuStyle={{ width: 540 }}
            onChange={(value: any) => {
              setPage(1);
              if (value !== null) {
                setSearchItems({
                  ...searchItems,
                  date1: format(value[0], "yyyy-MM-dd"),
                  date2: format(
                    value[1].setDate(value[1].getDate() + 1),
                    "yyyy-MM-dd"
                  ),
                  skip: 1,
                });

                setSearchFaktura({
                  ...searchFaktura,
                  date1: format(value[0], "yyyy-MM-dd"),
                  date2: format(
                    value[1].setDate(value[1].getDate()),
                    "yyyy-MM-dd"
                  ),
                  skip: 1,
                });
              } else {
                setSearchItems((prev: any) => {
                  const { date1, date2, ...rest } = prev;
                  return rest;
                });
              }
            }}
          />
          {!permissions.includes("STOK İDARƏSİ") ? (
            <div style={{ position: "absolute", top: 0, right: 25 }}>
              <Returned faktura={faktura} cemiQaytarma={cemiQaytarma} />
            </div>
          ) : null}
        </section>
      </div>
    </>
  );
};
export default SearchTarixche;
