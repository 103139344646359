import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { DateRangePicker, SelectPicker } from "rsuite";
import { getCheshidFakturaListAsync } from "../../../generalUsesReducers/getCheshidFakturaSlice";
import { getKodFakturaListAsync } from "../../../generalUsesReducers/getKodFakturaSlice";
import { TextField } from "@mui/material";
import format from "date-fns/format";

const SearchTransfer = (props: any) => {
  const { searchItems, setSearchItems, setPage } = props;
  const dispatch = useAppDispatch();
  const { mehsullar } = useAppSelector((state) => state.mehsulList);
  const { cheshidsFaktura } = useAppSelector(
    (state) => state.cheshidFakturaList
  );
  const { kodFaktura } = useAppSelector((state) => state.kodFakturaList);
  const { warehouse_ } = useAppSelector((state) => state.warehouseList);

  return (
    <>
      <div style={{ marginTop: "5em" }}>
        <section className="tableAlish-select-section">
          <div>
            <SelectPicker
              style={{ width: 180, marginLeft: "1em" }}
              data={mehsullar}
              size="lg"
              labelKey="mehsul"
              valueKey="id"
              placeholder="Məhsul"
              onSelect={(event: any) => {
                setSearchItems({
                  ...searchItems,
                  mehsulID: event,
                  skip: 1,
                });
                setPage(1);
                dispatch(getCheshidFakturaListAsync(event));
              }}
              onClean={() => {
                setSearchItems({
                  ...searchItems,
                  mehsulID: 0,
                  skip: 1,
                });
                setPage(1);
                dispatch(getCheshidFakturaListAsync(0));
              }}
            />
          </div>
          <SelectPicker
            virtualized
            style={{ width: 180, marginLeft: "1em" }}
            data={cheshidsFaktura}
            size="lg"
            labelKey="cheshid"
            valueKey="id"
            placeholder="Çeşid"
            onSelect={(event: any) => {
              setSearchItems({
                ...searchItems,
                cheshidID: event,
                skip: 1,
              });
              setPage(1);
              dispatch(getKodFakturaListAsync(event));
            }}
            onClean={() => {
              setSearchItems({
                ...searchItems,
                cheshidID: 0,
                skip: 1,
              });
              setPage(1);
              dispatch(getKodFakturaListAsync(0));
            }}
          />
          <SelectPicker
            virtualized
            style={{ width: 180, marginLeft: "1em" }}
            data={kodFaktura}
            size="lg"
            labelKey="kod"
            valueKey="id"
            placeholder="Kod"
            onSelect={(event: any) => {
              setSearchItems({
                ...searchItems,
                kodID: event,
                skip: 1,
              });
              setPage(1);
            }}
            onClean={() => {
              setSearchItems({
                ...searchItems,
                kodID: 0,
                skip: 1,
              });
              setPage(1);
            }}
          />
          <SelectPicker
            virtualized
            style={{ width: 180, marginLeft: "1em" }}
            data={warehouse_}
            size="lg"
            labelKey="warehouse"
            valueKey="id"
            placeholder="...anbardan"
            onSelect={(event: any) => {
              setSearchItems({
                ...searchItems,
                warehouseIDSource: event,
                skip: 1,
              });
              setPage(1);
            }}
            onClean={() => {
              setSearchItems({
                ...searchItems,
                warehouseIDSource: 0,
                skip: 1,
              });
              setPage(1);
            }}
          />
          <SelectPicker
            virtualized
            style={{ width: 180, marginLeft: "1em" }}
            data={warehouse_}
            size="lg"
            labelKey="warehouse"
            valueKey="id"
            placeholder="...anbara"
            onSelect={(event: any) => {
              setSearchItems({
                ...searchItems,
                warehouseIDTarget: event,
                skip: 1,
              });
              setPage(1);
            }}
            onClean={() => {
              setSearchItems({
                ...searchItems,
                warehouseIDTarget: 0,
                skip: 1,
              });
              setPage(1);
            }}
          />
          <TextField
            style={{ marginLeft: "1em", width: "12em", background: "white" }}
            label="barkod"
            variant="outlined"
            type="search"
            size="small"
            value={searchItems.barkod}
            onChange={(e) => {
              setSearchItems({
                ...searchItems,
                barkod: e.target.value,
                skip: 1,
              });
              setPage(1);
            }}
          />
          <DateRangePicker
            style={{ marginLeft: "1em" }}
            appearance="default"
            size="lg"
            placeholder={`${searchItems?.date1} ~ ${searchItems?.date2}`}
            placement="bottomEnd"
            cleanable={false}
            menuStyle={{ width: 540 }}
            onChange={(value: any) => {
              setPage(1);
              if (value !== null) {
                setSearchItems({
                  ...searchItems,
                  date1: format(value[0], "yyyy-MM-dd"),
                  date2: format(
                    value[1].setDate(value[1].getDate() + 1),
                    "yyyy-MM-dd"
                  ),
                  skip: 1,
                });
              } else {
                setSearchItems((prev: any) => {
                  const { date1, date2, ...rest } = prev;
                  return rest;
                });
              }
            }}
          />
        </section>
      </div>
    </>
  );
};

export default SearchTransfer;
