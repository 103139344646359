import React, { useEffect, useState } from "react";
import TableTarixche from "./components/TableTarixche";
import { API } from "../../baseUrl/baseUrl";
import qs from "qs";
import SearchTarixche from "./components/SearchTarixche";
import TarixcheFooter from "./components/TarixcheFooter";
import { useAuth } from "../../Sign/Auth";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getFakturaListAsync } from "../../generalUsesReducers/getFakturaSlice";
import { FakturaInterface } from "../../interfaces/interfaces";

const formatDate = (date: any) => {
  return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
    "0" + date.getDate()
  ).slice(-2)}`;
};

const SatishTarixchesi = () => {
  const { decode } = useAuth();
  const dispatch = useAppDispatch();
  const date2 = new Date();
  const date3 = new Date();
  date2.setDate(date2.getDate() + 1);

  const myDate2 = formatDate(date2);
  const myDate3 = formatDate(date3);

  const [search, setSearch] = useState({
    mehsulID: 0,
    cheshidID: 0,
    kodID: 0,
    warehouseID: 0,
    take: 10,
    skip: 1,
    date1: myDate3,
    date2: myDate2,
  });

  const [sales, setSales] = useState([]);
  const [dailyCash, setDailyCash] = useState<any>([]);
  const [special, setSpecial] = useState<any>([]);
  const [endirim, setEndirim] = useState(0);
  const getData = async () => {
    try {
      const result = await API(`/sales?${qs.stringify(search)}`);
      setSales(result.data);
      setDailyCash(result?.data[2]);
      setSpecial(
        result?.data[0]
          .filter((item: any) => item?.stocks?.mehsul_?.mehsul === "XIRDAVAT-F")
          ?.map((i: any) => Number(i.say) * Number(i.qiymet))
          .reduce((accumulator: number, item: number) => accumulator + item, 0)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const { faktura } = useAppSelector((state) => state.fakturaList);

  const cemiQaytarma = faktura[0]
    .map((i: FakturaInterface) => Number(i.maya_deyeri) * Number(i.say))
    .reduce((acc: number, cur: number) => acc + cur, 0);

  const getEndirimData = async () => {
    try {
      const response = await API(`/sales/endirim?${qs.stringify(search)}`);
      setEndirim(response?.data[2]);
    } catch (error) {
      console.log(error);
    }
  };

  const [page, setPage] = useState(1);

  const [searchFaktura, setSearchFaktura] = useState({
    stockID: 0,
    mehsulID: 0,
    cheshidID: 0,
    warehouseID: 0,
    kodID: 0,
    faktura: "Geri qaytarma",
    take: 100,
    skip: 1,
    date1: myDate3,
    date2: myDate2,
  });

  useEffect(() => {
    if (decode && decode?.warehouseID !== undefined) {
      setSearch({
        ...search,
        warehouseID: decode?.warehouseID,
      });
      setSearchFaktura({
        ...searchFaktura,
        warehouseID: decode?.warehouseID,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decode]);

  useEffect(() => {
    if (
      (decode && decode.warehouseID === undefined) ||
      (decode && decode.warehouseID !== undefined && search.warehouseID !== 0)
    ) {
      getData();
      getEndirimData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page, decode]);

  useEffect(() => {
    if (
      (decode && decode.warehouseID === undefined) ||
      (decode && decode.warehouseID !== undefined && search.warehouseID !== 0)
    ) {
      dispatch(getFakturaListAsync(searchFaktura));
    }
    console.log(faktura);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFaktura, decode]);

  const { permissionsList } = useAppSelector((state) => state.permissionsList);
  const permissions = permissionsList.map((item) => item.permission);
  return (
    <div style={{ marginTop: "5em" }}>
      <div>
        <SearchTarixche
          permissions={permissions}
          searchItems={search}
          setSearchItems={setSearch}
          setPage={setPage}
          searchFaktura={searchFaktura}
          setSearchFaktura={setSearchFaktura}
          cemiQaytarma={cemiQaytarma}
        />
      </div>
      <div>
        <TableTarixche
          permissions={permissions}
          searchItems={search}
          setSearchItems={setSearch}
          page={page}
          setPage={setPage}
          sales={sales}
          getData={getData}
        />
        <TarixcheFooter
          dailyCash={dailyCash}
          special={special}
          endirim={endirim}
          cemiQaytarma={cemiQaytarma}
        />
      </div>
    </div>
  );
};

export default SatishTarixchesi;
