import React, { useMemo, useEffect } from "react";
import { Outlet, NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../Sign/Auth";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getMehsulAsync } from "../generalUsesReducers/mehsulSlice";
import { getWarehouseListAsync } from "../generalUsesReducers/getWarehouseSlice";
import { getCheshidListAsync } from "../generalUsesReducers/getCheshidSlice";
import { getKodListAsync } from "../generalUsesReducers/getKodSlice";
import { getOlchuListAsync } from "../generalUsesReducers/getOlchuSlice";
import { getListPermissionAsync } from "../generalUsesReducers/listPermissionSlicer";
import jwtDecode from "jwt-decode";
import { BsPersonCircle } from "react-icons/bs";
import GetCookie from "../cookies/getCookie";
import { API } from "../baseUrl/baseUrl";
import NAVBAR from "../images/NAVBAR.png";
import RemoveCookie from "../cookies/removeCookie";
import { getCheshidFakturaListAsync } from "../generalUsesReducers/getCheshidFakturaSlice";
import { getCheshidTarixceListAsync } from "../generalUsesReducers/getCheshidTarixceSlice";
import { getKodTarixceListAsync } from "../generalUsesReducers/getKodTarixceSlice";
import { getKodFakturaListAsync } from "../generalUsesReducers/getKodFakturaSlice";

interface decodeType {
  exp: number;
  iat: number;
  id: number;
  user: string;
  warehouseID: number;
}

const Layout = () => {
  const dispatch = useAppDispatch();

  // codes for this page

  const { permissionsList } = useAppSelector((state) => state.permissionsList);

  const { signout } = useAuth();
  const navigate = useNavigate();

  const handleExit = async () => {
    await API.get(`/signout`);
    RemoveCookie("accessCookie");
    signout(() => navigate("/login"));
  };
  const token: any = GetCookie("accessCookie");
  const decode: decodeType = jwtDecode(token);

  const IDnumber: number = useMemo(() => {
    const test = decode.id;
    return test;
  }, [decode.id]);

  const permissions = permissionsList.map((item) => item.permission);

  // general using APIs
  useEffect(() => {
    dispatch(getMehsulAsync());
    dispatch(getCheshidListAsync(0));
    dispatch(getCheshidFakturaListAsync(0));
    dispatch(getCheshidTarixceListAsync(0));
    dispatch(getKodListAsync(0));
    dispatch(getKodTarixceListAsync(0));
    dispatch(getKodFakturaListAsync(0));
    dispatch(getOlchuListAsync(0));
    dispatch(getWarehouseListAsync());
    dispatch(getListPermissionAsync(IDnumber));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return (
    <div className="container">
      <nav>
        <section className="menu-container">
          <img className="navbar-logo" src={NAVBAR} alt="logo"></img>
          <div className="menu">
            <NavLink to="/">Məhsullar</NavLink>
            {permissions.includes("SATIŞ ET") ? (
              <NavLink to="satish">Satış</NavLink>
            ) : null}
            {permissions.includes("STOK İDARƏSİ") ? (
              <NavLink to="faktura">Faktura</NavLink>
            ) : null}
            {permissions.includes("STOK İDARƏSİ") ? (
              <NavLink to="transfers">Transfer</NavLink>
            ) : null}
            {permissions.includes("SATIŞ İCAZƏLƏRİ") ? (
              <NavLink to="satish_tarixchesi">Satış tarixçəsi</NavLink>
            ) : null}

            {permissions.includes("ADMİN SƏLAHİYYƏTLƏRİ") ? (
              <NavLink to="admin_panel">Admin paneli</NavLink>
            ) : null}
          </div>
          <div style={{ display: "flex", marginRight: "2em" }}>
            <BsPersonCircle
              style={{
                fontSize: "2em",
                color: "#b9d9c9",
              }}
            />
            <h4
              style={{
                color: "#b9d9c9",
                fontWeight: "normal",
                fontSize: "1.3em",
              }}
            >
              {decode.user}
            </h4>
          </div>
          <div className="exit-container">
            <button className="exit-btn" onClick={handleExit}>
              Çıxış
            </button>
          </div>
        </section>
      </nav>
      <Outlet />
    </div>
  );
};

export default Layout;
