import React from "react";
import { MdWarningAmber } from "react-icons/md";

const KassaFooter = ({
  endirim,
  setEndirim,
  qaliqPul,
  setQaliqPul,
  odenildi,
  setOdenildi,
  totalPrice,
  barcode,
  setBarcode,
  barkodErr,
  completeSale,
  getBarcodeManually,
  autoBarcodEntry,
  barkodRef,
  warning,
  setWarning,
  isSale,
}: {
  endirim: number;
  setEndirim: Function;
  qaliqPul: number;
  setQaliqPul: Function;
  odenildi: number;
  setOdenildi: Function;
  totalPrice: number;
  barcode: string;
  setBarcode: Function;
  barkodErr: string;
  completeSale: any;
  getBarcodeManually: any;
  autoBarcodEntry: any;
  barkodRef: any;
  warning: boolean;
  setWarning: Function;
  isSale: boolean;
}) => {
  return (
    <>
      <div className="total-satish">
        <div>
          <label htmlFor="endirim-satish">Endirim: </label>
          <input
            disabled={totalPrice === 0 || Number.isNaN(totalPrice)}
            id="endirim-satish"
            type="number"
            value={endirim === 0 ? "" : endirim}
            onChange={(e) => {
              setEndirim(e.target.value);
              setOdenildi(0);
              setQaliqPul(0);
            }}
          />
        </div>
        <div>
          <h4>
            <label>
              Məbləğ:
              <span
                style={{
                  display: "inline-block",
                  width: "5em",
                  textAlign: "right",
                }}
              >
                {(totalPrice && Math.round(totalPrice * 100) / 100) || 0}
                <small
                  style={{
                    color: "black",
                  }}
                >
                  AZN
                </small>
              </span>
            </label>
          </h4>
        </div>
        <div>
          <label htmlFor="odenildi-satish">Ödənildi: </label>
          <input
            disabled={totalPrice === 0 || Number.isNaN(totalPrice)}
            id="odenildi-satish"
            type="number"
            value={odenildi === 0 ? "" : odenildi}
            onChange={(e) => {
              setQaliqPul(
                isNaN(totalPrice - parseFloat(e.target.value))
                  ? 0
                  : totalPrice - parseFloat(e.target.value)
              );
              setOdenildi(e.target.value);
              if (
                Math.round(totalPrice - parseFloat(e.target.value) * 100) /
                  100 >
                0
              ) {
                setWarning(true);
              } else {
                setWarning(false);
              }
            }}
          />
          <label htmlFor="qaliq-pul-satish">Qalıq pul: </label>
          <input
            id="qaliq-pul-satish"
            readOnly
            value={Math.round(qaliqPul * 100) / 100}
          />
        </div>
        <div
          style={{
            display: "inline-block",
            width: "5em",
            textAlign: "right",
          }}
        >
          {warning ? (
            <MdWarningAmber
              className="satish-warning "
              title="Ödəniş məbləği düzgün daxil edilməyib"
            />
          ) : (
            " "
          )}
        </div>
      </div>
      <div className="container-satish-footer">
        <form>
          <div id="barkod-sat" style={{ display: "flex" }}>
            <div className="barkod-container">
              <input
                name="barkod-satish"
                type="text"
                id="barkod-satish"
                autoComplete="off"
                ref={barkodRef}
                onChange={(e) => {
                  setBarcode(e.target.value);
                }}
                value={barcode}
                onPaste={autoBarcodEntry}
                required
              />

              <label htmlFor="barkod-satish" id="barkod-label">
                Barkod
              </label>
            </div>
            <button onClick={getBarcodeManually}>Barkod</button>
            <p>{barkodErr}</p>
          </div>
        </form>
        <div>
          <button
            disabled={
              totalPrice <= 0 ||
              Number.isNaN(totalPrice) ||
              odenildi <= 0 ||
              qaliqPul > 0 ||
              isSale === true
            }
            title={
              totalPrice <= 0 ||
              Number.isNaN(totalPrice) ||
              odenildi <= 0 ||
              qaliqPul > 0
                ? "Satış dəyərlərini tam və düzgün daxil edin"
                : ""
            }
            type="button"
            className="submit-btn satish-submit"
            onClick={completeSale}
          >
            Satışı bitir
          </button>
        </div>
      </div>
    </>
  );
};

export default KassaFooter;
