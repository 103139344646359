import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Alishpage from "./Pages/Alish/Alishpage";
import Satish from "./Pages/Satish/Satish";
import Private from "./Sign/Private";
import Faktura from "./Pages/Faktura/Faktura";
import AdminPanel from "./Pages/Administration/AdminPanel";
import Register from "./Pages/Administration/Users & register/Register";
import Warehouse from "./Pages/Administration/warehouses/warehouse";
import RolePage from "./Pages/Administration/Roles & Permissions/RolePage";
import ChangeProdValue from "./Pages/Administration/ChangeProductValue/ChangeProdValue";
import DeleteSatishRow from "./Pages/Administration/DeleteSatishRow/DeleteSatishRow";
import { AuthProvider } from "./Sign/Auth";
import Login from "./Sign/Login";
import UPS from "./Pages/UPS";
import Layout from "./Pages/Layout";
import SatishTarixchesi from "./Pages/SatishTarixchesi/SatishTarixchesi";
import TransferStorage from "./Pages/TransferStorage/TransferStorage";

function App() {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Private />}>
            <Route path="/" element={<Layout />}>
              <Route index element={<Alishpage />} />
              <Route path="satish" element={<Satish />} />
              <Route path="faktura" element={<Faktura />} />
              <Route path="transfers" element={<TransferStorage />} />
              <Route path="satish_tarixchesi" element={<SatishTarixchesi />} />
              <Route path="/admin_panel" element={<AdminPanel />}>
                <Route
                  path="/admin_panel"
                  element={<Navigate replace to="roles" />}
                />
                <Route path="roles" element={<RolePage />} />
                <Route path="registration" element={<Register />} />
                <Route path="warehouses" element={<Warehouse />} />
                <Route
                  path="change_product_value"
                  element={<ChangeProdValue />}
                />
                <Route path="delete_sales_row" element={<DeleteSatishRow />} />
              </Route>
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<UPS />} />
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
