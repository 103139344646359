import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { API } from "../baseUrl/baseUrl";
import {
  getTransfer,
  transferInterface,
  searchTransferInteface,
} from "../interfaces/interfaces";
import qs from "qs";

const getTransferInitialState: getTransfer = {
  transfer: [[], 0],
  status: "idle",
};

export const getTransferListAsync = createAsyncThunk<
  [Array<transferInterface>, number],
  searchTransferInteface
>("getTransferListAsync/transferListSlicer", async (urlParams) => {
  try {
    const response = await API.get(`/transfer?${qs.stringify(urlParams)}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
});

export const getTransferListSlice = createSlice({
  name: "getTransferListSlicer",
  initialState: getTransferInitialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTransferListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getTransferListAsync.fulfilled,
        (state, action: PayloadAction<[Array<transferInterface>, number]>) => {
          state.transfer = action.payload;
          state.status = "idle";
        }
      )
      .addCase(getTransferListAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});
export const TransferList = (state: RootState) => state.transferList.transfer;
export default getTransferListSlice.reducer;
