import React from "react";
import { Table, Loader } from "rsuite";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { API } from "../../../../baseUrl/baseUrl";
import TransferModal from "./TransferModal";
import DeleteModal from "../../../../generalComponents/DeleteModal";
import { Alert } from "../../../../alert/alert";
import EditStockModal from "./EditStockModal";
import { getProdListAsync } from "../../../../generalUsesReducers/stockSlice";
import FakturaModal from "../../../Faktura/components/FakturaModal";
import { searchParametersInterface } from "../../../../interfaces/interfaces";
import Paginations from "../../../../generalComponents/Paginations";
import ChangePrice from "./ChangePrice";

const { Column, HeaderCell, Cell } = Table;

const TableAlish = ({
  search,
  setSearch,
  page,
  setPage,
  permissions,
}: {
  search: searchParametersInterface;
  setSearch: any;
  page: number;
  setPage: any;
  permissions: any;
}) => {
  const dispatch = useAppDispatch();
  const { products } = useAppSelector((state) => state.productList);

  const handleDelete = async (id: number) => {
    try {
      const response = await API.delete(`/stock/${id}`);
      await dispatch(getProdListAsync(search));
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      Alert({
        type: "error",
        title: error.response.data,
        placement: "topCenter",
        duration: 5000,
      });
      console.log(error);
    }
  };

  const { status } = useAppSelector((state) => state.productList);
  if (status === "loading") {
    return <Loader center size="lg" />;
  }

  return (
    <>
      <div>
        <div className="table-place-div">
          <div>
            <Table
              className="table-alish-olculer"
              height={509}
              data={products && products[0]}
              onRowClick={(rowData: object) => {
                console.log(rowData);
              }}
            >
              {
                <Column minWidth={80} flexGrow={1}>
                  <HeaderCell>Məhsul</HeaderCell>
                  <Cell style={{ padding: "6px" }} dataKey="mehsul_.mehsul">
                    {(rowData) => (
                      <FakturaModal
                        rowData={rowData}
                        search={search}
                        permissions={permissions}
                      />
                    )}
                  </Cell>
                </Column>
              }
              <Column minWidth={120} flexGrow={2}>
                <HeaderCell>Çeşid</HeaderCell>
                <Cell dataKey="cheshid_.cheshid" />
              </Column>
              <Column width={100}>
                <HeaderCell>Kod</HeaderCell>
                <Cell dataKey="kod_.kod" />
              </Column>
              <Column width={100} align="center">
                <HeaderCell>Miqdar</HeaderCell>
                <Cell dataKey="say" />
              </Column>
              <Column width={150} align="center">
                <HeaderCell>Ölçü vahidi</HeaderCell>
                <Cell dataKey="olchu_.olchu" />
              </Column>
              <Column width={150} align="center">
                <HeaderCell>Barkod</HeaderCell>
                <Cell dataKey="barkod"></Cell>
              </Column>
              <Column width={120} align="center">
                <HeaderCell>Anbar</HeaderCell>
                <Cell dataKey="warehouseKey.warehouse" />
              </Column>
              <Column width={100} align="center">
                <HeaderCell> Qiymət</HeaderCell>
                <Cell style={{ padding: "6px" }}>
                  {(rowData: object) => (
                    <ChangePrice
                      rowData={rowData}
                      search={search}
                      permissions={permissions}
                    />
                  )}
                </Cell>
              </Column>
              <Column width={150} align="center">
                <HeaderCell>Kampaniya endirimi</HeaderCell>
                <Cell dataKey="kampaniya_endirimi" />
              </Column>
              {permissions.includes("STOK İDARƏSİ") ? (
                <>
                  <Column width={70} align="center" fixed="right">
                    <HeaderCell>#</HeaderCell>
                    <Cell style={{ padding: "6px" }}>
                      {(rowData: object) => (
                        <EditStockModal rowData={rowData} search={search} />
                      )}
                    </Cell>
                  </Column>
                  <Column width={85} align="center" fixed="right">
                    <HeaderCell>#</HeaderCell>
                    <Cell style={{ padding: "6px" }}>
                      {(rowData: object) => (
                        <DeleteModal
                          rowData={rowData}
                          handleDelete={handleDelete}
                        />
                      )}
                    </Cell>
                  </Column>
                  <Column width={90} align="center" fixed="right">
                    <HeaderCell>#</HeaderCell>
                    <Cell style={{ padding: "6px" }}>
                      {(rowData: object) => (
                        <TransferModal rowData={rowData} search={search} />
                      )}
                    </Cell>
                  </Column>
                </>
              ) : null}
            </Table>
          </div>
        </div>
        <div>
          <Paginations
            totalPage={products && products[1]}
            page={page}
            setPage={setPage}
            searchData={search}
            setSearchData={setSearch}
          />
        </div>
      </div>
    </>
  );
};

export default TableAlish;
