import React, { useState } from "react";
import { Table, Loader, Button, Modal } from "rsuite";
import { useAppSelector } from "../../../store/hooks";
import { FakturaInterface } from "../../../interfaces/interfaces";
import format from "date-fns/format";

const { Column, HeaderCell, Cell } = Table;

const Returned = (props: any) => {
  const {
    faktura,
    cemiQaytarma,
  }: {
    faktura: [FakturaInterface[], number];
    cemiQaytarma: number;
  } = props;
  const { load } = useAppSelector((state) => state.loading);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const height = window.innerHeight * 0.55;

  return (
    <div>
      <Button
        appearance={faktura[0].length > 0 ? "primary" : "subtle"}
        disabled={faktura[0].length <= 0}
        onClick={() => {
          setOpen(true);
        }}
      >
        Geri qaytarma
      </Button>

      <div>
        {load === true ? (
          <Loader center size="lg" />
        ) : (
          <Modal open={open} onClose={handleClose} size="lg">
            <Modal.Header>
              <Modal.Title>
                <div>
                  <h3 style={{ margin: "0", padding: "0" }}>
                    Geri qaytarılmış məhsullar
                  </h3>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <Table
                  height={height}
                  data={faktura[0]}
                  onRowClick={(rowData: object) => {
                    console.log(rowData);
                  }}
                >
                  <Column width={130} align="center">
                    <HeaderCell>Məhsul</HeaderCell>
                    <Cell dataKey="stocks.mehsul_.mehsul" />
                  </Column>
                  <Column width={150} align="center">
                    <HeaderCell>Çeşid</HeaderCell>
                    <Cell dataKey="stocks.cheshid_.cheshid" />
                  </Column>
                  <Column width={140} align="center">
                    <HeaderCell>Kod</HeaderCell>
                    <Cell dataKey="stocks.kod_.kod" />
                  </Column>
                  <Column width={100} align="center">
                    <HeaderCell>Miqdar</HeaderCell>
                    <Cell dataKey="say" />
                  </Column>
                  <Column width={80} align="center">
                    <HeaderCell>Ölçü vahidi</HeaderCell>
                    <Cell dataKey="stocks.olchu_.olchu" />
                  </Column>
                  <Column width={120} align="center">
                    <HeaderCell>Məbləğ</HeaderCell>
                    <Cell>
                      {(rowData) =>
                        (rowData?.maya_deyeri * rowData.say).toFixed(2)
                      }
                    </Cell>
                  </Column>
                  <Column width={150} align="center">
                    <HeaderCell>Tarix</HeaderCell>
                    {/* <Cell>{(rowData) => rowData?.tarix?.slice(0, 10)}</Cell> */}
                    <Cell>
                      {(rowData) =>
                        rowData?.tarix &&
                        format(
                          new Date(rowData?.tarix).setHours(
                            new Date(rowData?.tarix).getHours() - 4
                          ),
                          "dd.MM.yyyy / HH:mm:ss"
                        )
                      }
                    </Cell>
                  </Column>
                </Table>
                <h6 className="text-center mt-3">{`Cəmi məbləğ: ${cemiQaytarma} AZN`}</h6>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Returned;
