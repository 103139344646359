export const apiConfig = (): string => {
  let api: string | any, hostname: string;
  if (typeof window !== "undefined") {
    api = "http://localhost:5000";
    hostname = document.location.hostname;

    if (hostname === "localhost") {
      api = "http://localhost:5000";
    } else if (hostname === "stil-warehouse.com") {
      api = "https://api.stil-warehouse.com";
    }
  }

  return api;
};
